.popapWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 4;
    background: rgba(0, 0, 0, 0.283);
    backdrop-filter: blur(3px);

    overflow: hidden;
    padding: 1rem;

    display: flex;
    flex-direction: column;
    align-items: center;

    transition: all linear .2s;


    .popapContent {
        z-index: 5;
        width: 100%;
        max-width: 600px;
        background: #fff;
        overflow-y: scroll;
        // max-height: 500px;
    }
    .popapTopLIne {
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        height: 50px;
        // background: #CF404D;
        background: #2D3E4F;

        padding: 5px 20px;

        display: flex;
        justify-content: space-between;
        align-items: center;

        .popapHeaderTitle {
            font-family: 'Oswald';
            font-style: normal;
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 2rem;
            // text-transform: uppercase;
            color: #ffffff;
        }

        .popapHeaderExit {
            display: block;
            cursor: pointer;
            position: relative;

            width: 40px;
            height: 28px;

            &::after, &::before {
                content: '';
            }
    
            &::after, &::before, span {
                position: absolute;
                left: 0;
                
                height: 4px;
                border-radius: 1px;
                background: rgb(233, 233, 233);
                transition: all .2s linear;
            }
            span {
                top: 50%;
                transform: translateY(-50%);
                width: 0%;
            }
            &::after {
                width: 100%;
                top: 46%;
                transform: rotate(45deg) 
            }
            &::before {
                width: 100%;
                bottom: 38%;
                transform: rotate(-45deg);
            }

            &.active {
                span {
                    top: 50%;
                    transform: translateY(-50%);
                    width: 100%;
                }
                &::after {
                    width: 80%;
                    top: 0%;
                    transform: rotate(0deg) 
                }
                &::before {
                    width: 100%;
                    bottom: 0%;
                    transform: rotate(0deg);
                }

            }
        }
    }
    .popapMediaBlock {
        padding: 1.3rem;
        height: calc(100% - 50px);
        display: flex;
        flex-direction: column;
        min-width: 10rem;
        line-height: 1.333rem;
        color: rgba(0, 0, 0, 0.74);


        

        .projImg {
            width: 100%;
            flex: 1 1 auto;
            // background: rgb(200, 35, 35);
            margin-bottom: 1rem;
            position: relative;
            img{
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        .projContent {
            flex: 0 0 auto;
            overflow: hidden;
        }
        .projTitle {
            font-family: 'Oswald';
            font-style: normal;
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 2rem;
            // text-transform: uppercase;
            color: #090909;

            margin-bottom: 0.6rem;
        }
        .projData {
            font-size: 1rem;
            color: #2D3E4F;

            margin-bottom: 0.5rem;
        }
        .projDes {
            margin-bottom: 0.5rem;
            
        }
        .projLink {
            font-size: 1rem;
            color: #1c4064;
            text-decoration: underline;
            margin-bottom: 0.5rem;
        }
        .projGit {
            width: 100%;
            padding: 5px;
            border-radius: 3px;
            display: inline-block;
            background: #1c4064;
            font-size: 1rem;
            color: #ffffff;
            text-decoration: underline;
            margin-bottom: 0.5rem;
        }
    }
}