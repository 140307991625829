.main { 
    width: 100%;
    height: 100vh;
    background: #3a3937;

    /* .main__container */

    &__container {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
        img{
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: 0;
        }
    }

    &__content {
        position: relative;
        margin: 0 auto;
        width: 100%;
        max-width: 600px;
        height: 100%;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 1;
    }

    /* .main__title */

    &__title {
        font-family: 'Oswald';
        font-style: normal;
        font-weight: 400;
        font-size: 3.33rem;
        line-height: 0.9em;
        letter-spacing: .08em;
        text-transform: uppercase;
        text-align: center;

        color: #fff;

        margin-bottom: 1rem;
    }

    /* .main__suptitle */

    &__suptitle {
        font-family: 'Oswald';
        font-style: normal;
        font-weight: 400;
        font-size: 1.3rem;
        line-height: 0.9em;
        letter-spacing: .03em;

        text-align: center;

        color: #fff;

        margin-bottom: 2.3rem;
    }

    /* .main__buttons-block */

    &__buttons-block {
        display: flex;
        align-items: center;
        gap: .5em;
    }
}

