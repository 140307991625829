.entering {
    opacity: 1;
}
.entered {
    opacity: 1;

}
.exiting {
    opacity: 0;
}
.exited{
    opacity: 0;
}