.quote {
    margin-left: auto;
    display: inline-block;

    span {
        color: #393939;
        margin-top: .8rem;
        display: block;
        width: 100%;
        text-align: end;
    }
}