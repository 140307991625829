.swiper {
    width: 90%;
    height: 100%;
  }
  
  .swiper-slide {
    position: relative;
    border-radius: 5px;

    img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    // .swiper-slide-shadow {
    //     opacity: 0;
    // }
  }
  
//   .swiper-slide:nth-child(1n) {
//     // background-color: rgb(206, 17, 17);
//     background-color: #fff;
//   }
  
//   .swiper-slide:nth-child(2n) {
//     // background-color: rgb(0, 140, 255);
//     background-color: #fff;
//   }
  
//   .swiper-slide:nth-child(3n) {
//     // background-color: rgb(10, 184, 111);
//     background-color: #fff;
//   }
  
//   .swiper-slide:nth-child(4n) {
//     // background-color: rgb(211, 122, 7);
//     background-color: #fff;
//   }
  
//   .swiper-slide:nth-child(5n) {
//     background-color: rgb(118, 163, 12);
//   }
  
//   .swiper-slide:nth-child(6n) {
//     background-color: rgb(180, 10, 47);
//   }
  
//   .swiper-slide:nth-child(7n) {
//     background-color: rgb(35, 99, 19);
//   }
  
//   .swiper-slide:nth-child(8n) {
//     background-color: rgb(0, 68, 255);
//   }
  
//   .swiper-slide:nth-child(9n) {
//     background-color: rgb(218, 12, 218);
//   }
  
//   .swiper-slide:nth-child(10n) {
//     background-color: rgb(54, 94, 77);
//   }