

.entering_popap {
    opacity: 1;
}
.entered_popap {
    opacity: 1;
}
.exiting_popap{
    opacity: 0;
}
.exited_popap {
    opacity: 0
}