@font-face {
  font-family: Jost;
  font-display: swap;
  src: url("assets/fonts/Jost-Regular.woff") format("woff"), url("assets/fonts/Jost-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Oswald;
  font-display: swap;
  src: url("assets/fonts/Oswald-Regular.woff") format("woff"), url("assets/fonts/Oswald-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: icomoon;
  font-display: swap;
  src: url("assets/fonts/icomoon.woff") format("woff"), url("assets/fonts/icomoon.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

* {
  padding: 0;
  margin: 0;
  border: 0;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:focus,
:active {
  outline: none;
}

a:focus,
a:active {
  outline: none;
}

html,
body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1;
  font-size: 14px;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

input,
button,
textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  background-color: inherit;
  cursor: pointer;
  color: inherit;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a,
a:visited {
  text-decoration: none;
  color: inherit;
}
a:hover {
  text-decoration: none;
  color: inherit;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

[class^=icon-],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-camera:before {
  content: "\e905";
}

.icon-cloud:before {
  content: "\e906";
}

.icon-meh:before {
  content: "\e907";
}

.icon-window:before {
  content: "\e908";
}

.icon-behanse:before {
  content: "\e900";
  color: #fff;
}

.icon-facebook:before {
  content: "\e901";
  color: #fff;
}

.icon-insta:before {
  content: "\e902";
  color: #fff;
}

.icon-mail:before {
  content: "\e903";
  color: #fff;
}

.icon-tvit:before {
  content: "\e904";
  color: #fff;
}

html {
  font-size: 15px;
}
html {
  font-size: 15px;
  @media (max-width: 1200px) {
     font-size: calc(10px + (15 - 10) * (100vw - 320px) / (1200 - 320))
 }
}

body {
  font-family: "Jost";
  font-weight: 400;
  font-size: 1rem;
  background: #f6f6f6;
  color: #2F2F2F;
}
#root {
  width: 100%;
  height: 100%;

  // overflow: hidden;
}

.-lock {
  overflow: hidden;
}

.main-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.page {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 320px;
  @media (max-width: 991.98px) {
    left:0px;
  }
}

.page-enter {
  opacity: 0;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}


.burger {
  display: none;
}

