.form__input {
    overflow: hidden;
    height: 3rem;
    background: #000;
    flex: 1 1 auto;
    background: #FFFFFF;
    box-shadow: 0px 0px 0px rgba(64, 64, 71, 0.3);
    border-radius: 3px;
    transition: all linear .1s;

    @keyframes shake {
        0% { transform: translate(-1px, 0); }
        33% { transform: translate(1px, 0); }
        66% { transform: translate(-1px, 0); }
        100% { transform: translate(1px, 0); }
      }

    &.empty {
        border: 3px solid #ff3434;
        background: #ffc8c8;
        animation: shake .2s;
        animation-iteration-count: 2;
    }


    input {
        width: 100%;
        height: 100%;
        background: inherit;

        padding: 5px 2rem;

        &::placeholder {
            font-family: 'Oswald';
            font-style: normal;
            font-weight: 400;
            font-size: 1.1rem;
            color: rgba(101, 101, 101, 0.74);
        }

    }
}
.form__textarea {
    overflow: hidden;
    width: 100%;
    min-height: 25rem;
    background: #FFFFFF;
    box-shadow: 0px 0px 0px rgba(64, 64, 71, 0.3);
    border-radius: 3px;
    margin-bottom: 1.4rem;

    textarea {
        resize: none;
        width: 100%;
        height: 100%;
        background: inherit;
        padding: 1.5rem 2rem;

        &::placeholder {
            font-family: 'Oswald';
            font-style: normal;
            font-weight: 400;
            font-size: 1.1rem;
            color: rgba(101, 101, 101, 0.74);
        }
    }
}