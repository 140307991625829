.sliderWrapper {
    width: 100%;
    height: 100%;
    // border: 1px #000 solid;
}

.wrapperPhotoModule {
    width: 100%;
    height: 100%;
}

.img {
    position: absolute;
    top: 0;
    left: 0;
    display: none;

    &.active {
        display: block;
    }
}