.galeryBlock {
    margin-bottom: 2rem;
    margin-top: 1.5rem;
    width: 100%;
    height: 30rem;
    padding: 10px;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas: 'main main left' 'main main left' 'bottom bottom bottom';
    gap: 1rem;
    .galeryItem {
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .galeryItemMain {
        border-radius: 40px 0 0 0;
        grid-area: main;
        background: rgb(178, 178, 178);
    }
    .galeryItemLeft {
        grid-area: left;
        background: rgb(178, 178, 178);
    }
    .galeryItemBottom {
        grid-area: bottom;
        background: rgb(178, 178, 178);
        border-radius: 0 0 40px 0;
    }
}

.photoStor {
    margin: 0 auto;
    width: 20rem;
    height: 25rem;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}