.paragraph {
    text-indent: 1.5rem;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.45em;
    letter-spacing: 0.02em;
    
    color: #4F4F4F;
    margin-bottom: 1.5rem;
}