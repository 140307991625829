.languageBlock {
    position: absolute;
    top: 2.5rem;
    right: 3rem;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 7px; 
}
.langCheckBox {
    position: relative;
    width: 60px;
    height: 40px;
    border: 3px solid rgba(255, 255, 255, 0.784);
    border-radius: 20px;

    .langCheckBox_legend {
        padding: 5px;
        width: 100%;
        height: 100%;
        display: flex;
        color: rgba(255, 255, 255, 0.801);
        font-family: 'Oswald';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        justify-content: space-between;
        align-items: center;
    }

}
.langCheckBox_circle {
    // display: none;
    cursor: pointer;
    position: absolute;
    top: 2px;
    left: 22px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: rgb(255, 255, 255);
    transition: left 0.1s cubic-bezier(0.47, 0, 0.75, 0.72);

    &.active {
        left: 2px;
    }
}