
    .about__footer {
        flex: 0 0 9rem;
        background: #CF404D;
    }

    .about__containerFooter {
        max-width: 940px;
        height: 100%;
        margin: 0 auto;
        padding: 1rem;

        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    /* .about__action */

    .about__action {
        font-family: 'Oswald';
        font-style: normal;
        font-weight: 400;
        font-size: 2.1rem;
        color: #FFFFFF;
    }