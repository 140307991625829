.contact {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    /* .contact__head */

    &__head {
        flex: 1 1 auto;
        padding: 3.8rem 5px;
        background: #fafafa;
    }

    /* .contact__container */

    &__container {
    }

    /* .contact__title */

    &__title {
        margin-bottom: 1.5rem;
    }

    /* .contact__suptitle */

    &__suptitle {
        margin-bottom: 3.8rem;
    }

    /* .contact__form */

    &__form {
        margin: 0 auto;
    }

    /* .contact__footer */

    &__footer {
        flex: 0 0 auto;
        display: flex;
        justify-content: center;
        span {
            display: inline-block;
            padding: 1rem;
            font-family: 'Oswald';
            font-style: normal;
            font-weight: 400;
            font-size: 1.1rem;
            text-align: center;
            
            color: rgba(0, 0, 0, 0.74);
        }
    }
}

