.box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
  .container{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .circle{
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: rgb(40, 57, 181);
      animation: move 500ms linear 0ms infinite;
      margin-right: 30px;
      
      &:first-child{
        position: absolute;
        top:0;
        left:0;
        animation: grow 500ms linear 0ms infinite;
      }
      
      &:last-child{
        position: absolute;
        top: 0;
        right: 0;
        margin-right: 0;
        animation: grow 500ms linear 0s infinite reverse;
      }
    }
  }
  
  @keyframes grow {
    from {transform: scale(0,0); opacity: 0;}
    to {transform: scale(1,1); opacity: 1;}
  }
  
  @keyframes move {
    from {transform: translateX(0px)}
    to {transform: translateX(45px)}
  }