.error {
    width: 100%;
    height: 100%;
    background: rgb(236, 236, 236);

    &__container {
        max-width: 500px;
        height: 100%;

        margin: 0 auto;
        padding: 3.8rem 1rem;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    &__back {
        color: rgb(20, 79, 216);
        &:hover {
            text-decoration: underline;
        }
    }
}